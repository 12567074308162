import { find, findIndex } from 'lodash';
import { validate as isUUID } from 'uuid';
import { MaterialisedPropertyData, Maybe } from '@property-folders/contract';
import { DocumentFieldType, PathSegments, PathString, PathType, ValidationDefnType } from '@property-folders/contract/yjs-schema/model';
import { ValidationNode } from '../yjs-schema/property/validation/process-validation';
import { parseInt2 } from './formatting/functions/parseInt2';

export function mergePaths(...paths: (PathType|undefined)[]): PathSegments {
  return paths.flatMap(normalisePathToStrArray);
}

export function mergePathsAsStr(...paths: (PathType|undefined)[]): PathString {
  return normalisePathToStr(mergePaths(...paths));
}

/**Convert string representation of path into array representation of path
 *
 * Conventions: .path.component: A prefixed . will result in ['', 'path', 'component'], so an empty
 *  string at the start of a path array shall signify relative path. In some places '.' and '..' are
 *  used, however these should never be converted to a string path, and should not be considered a
 *  canonical PathType
 *  '': Transaction root. Should correspond with an empty path array
 *
 * @param path
 * @returns
 */
export function normalisePathToStrArray(path: PathType | undefined) {

  if (Array.isArray(path)) {
    return path;
  } else {
    // So an empty string at the start of a path component could mean relative path, where as an
    // empty string means transaction root. Let's preserve both for now.
    return path ? path.split('.') : [];
  }
}

export function normalisePathToStr(path: PathType) {
  if (Array.isArray(path)) {
    return path.join('.');
  } else {
    return path;
  }
}

/**
 * Note: The special case of an empty string (to get the root) has some confusing behaviour. The
 * parent will be the node, and the indexer will be an empty string. Doing parent[indexer] in this
 * case will (probably) result in undefined. An alternative would be to create a parent, with the
 * root at a pre-specified key indexer, but this isn't an amazing solution either.
 *
 * @param path Path string, delimited by . and arrays are inidcated with [#], and still seperated by
 *  '.'
 * @param treeTop Root node of the tree that we're searching with the path
 * @param noErrorOnBadPath Returns empty values on error. This also means parent[indexer] will
 *  return undefined instead of crashing
 * @param mutateCreateBlank Will create objects all the way to the bottom of the path (including the
 *  leaf) if they aren't already objects. Note: an array is still classified as an object, this will
 *  clobber other basic types and undefines. overrides and implies noErrorOnBadPath
 * @keysNotIndex Used by validation tree, because we're going to have a bad time tracking index positions otherwise, and
 *  it is not being pushed to the datastore
 * @returns an object with a parent and indexer key, such that parent[indexer] will be the leaf node
 *  of the object specified in the path in treeTop. Empty parent and empty string if an error
 *  occured with noErrorOnBadPath enabled. Indexer returns a string representation of the index even
 *  if the leaf is an array value
 */
export function getPathParentAndIndex(path: PathType | undefined | null, treeTop: Record<string, any>, noErrorOnBadPath: true, mutateCreateBlank?: boolean, keysNotIndex?: boolean): any;
export function getPathParentAndIndex(path: PathType, treeTop: Record<string, any>, noErrorOnBadPath?: false, mutateCreateBlank?: boolean, keysNotIndex?: boolean): any;
export function getPathParentAndIndex(path: PathType | undefined | null, treeTop: Record<string, any>, noErrorOnBadPath = false, mutateCreateBlank = false, keysNotIndex = false) {
  if (mutateCreateBlank) {
    noErrorOnBadPath = true;
  }
  if (noErrorOnBadPath && path == null) return { parent: {}, indexer: '' };
  if (path == null) throw new Error('No path given!');
  let parent = treeTop;
  let indexer = '';
  const pathStr = normalisePathToStr(path);
  if (!pathStr && mutateCreateBlank) {
    return { parent: !parent[indexer] && typeof parent[indexer] !== 'object' ? treeTop : {}, indexer: '' };
  } else if (!pathStr) {
    return { parent: treeTop, indexer: '' };
  }
  try {
    const pathSegments = normalisePathToStrArray(path);
    if (pathSegments.length === 1) {
      return { parent: treeTop, indexer: pathSegments[0] };
    }
    for (const segistr in pathSegments) {
      const segi = parseInt(segistr, 10);
      const segment = pathSegments[segi];
      if (segment.startsWith('[')) {
        indexer = segment.slice(1, segment.length-1);
        parent = Object.values(parent); // Should do the same for both Array and Object
        if (isUUID(indexer) && !keysNotIndex) {
          const idx = findIndex(parent as {id: string}[], elem => elem.id === indexer);
          indexer = `${idx}`;
        }
        if (indexer === '' && Array.isArray(parent)) {
          indexer = `${parent.length}`;
        }
      } else {
        indexer = segment;
      }
      if (mutateCreateBlank && !parent[indexer] && typeof parent[indexer] !== 'object' && segi+1 < pathSegments.length) {
        const followingSegment = pathSegments[(parseInt2(segi)??0)+1];
        const newVal = followingSegment === '[]' ? [] : {};
        const currentPath = pathSegments.slice(0,segi+1);
        const { parent, indexer } = getPathParentAndIndex(currentPath, treeTop, false, false);
        if (Array.isArray(parent)) {
          parent.splice(parseInt(indexer),0, newVal);
        } else {
          parent[indexer] = newVal;
        }
      }
      if (segi < pathSegments.length - 1) {
        parent = parent[indexer];
      }
    }
  } catch (error) {
    if (noErrorOnBadPath) return { parent: {}, indexer: '' };
    else throw error;
  }

  return { parent, indexer };
}
export function getValueByPath(path: PathType | null | undefined, treeTop: Record<string, any>, noErrorOnBadPath: true, keysNotIndex?: boolean): any;
export function getValueByPath(path: PathType, treeTop: Record<string, any>, noErrorOnBadPath?: false, keysNotIndex?: boolean): any;
export function getValueByPath(path: PathType | null | undefined, treeTop: Record<string, any>, noErrorOnBadPath = false, keysNotIndex = false): any {
  if (path == null && noErrorOnBadPath) {
    return undefined;
  }
  if (path == null) {
    throw new Error('No path given!');
  }
  const pathSegs = normalisePathToStrArray(path);

  // Strip off relative path
  if (pathSegs[0] === '') {pathSegs.splice(0,1);}

  const { parent, indexer } = getPathParentAndIndex(pathSegs, treeTop, noErrorOnBadPath, false, keysNotIndex);
  try {
    if (pathSegs.length && parent == null && !noErrorOnBadPath) {
      return parent[indexer];
    }
    if (pathSegs.length && parent == null) {
      return undefined;
    }
    return pathSegs.length ? parent[indexer] : parent; // Empty paths should just return the top level
  } catch (error) {
    if (noErrorOnBadPath) return undefined;
    else throw error;
  }
}

export const displayChangeSuffix = '_display';
function sanitisePathSegment(segment: string | number): string | number {
  if (typeof segment === 'number') return segment;
  return segment.endsWith(displayChangeSuffix)
    ? segment.slice(0, segment.lastIndexOf(displayChangeSuffix))
    : segment;
}

/**
 *
 * @param path Root lookups should given an empty array, not an array with 1 empty entry
 * @param definition
 * @returns
 */
export function getValidationDefnByPath(
  path: PathSegments,
  definition: Maybe<ValidationDefnType>,
  reportMissing?: (defn: ValidationDefnType) => void
) {
  let wDefn: Maybe<ValidationDefnType> = definition;
  const segments = path.map(sanitisePathSegment);
  for (const segment of segments) {
    if (typeof segment === 'number' || segment.startsWith('[')) {
      wDefn = wDefn?._children;
    } else {
      wDefn = (wDefn?.[segment] ?? wDefn?.['*']) as Maybe<ValidationDefnType>;
    }
  }

  if (!wDefn && reportMissing && segments[0]) {
    const newDefn: ValidationDefnType = {};
    let cur: Record<string, any> = newDefn;
    for (const segment of segments) {
      if (typeof segment === 'number' || segment.startsWith('[')) {
        cur._children = { _required: false };
        cur = cur._children;
      } else {
        const layer: ValidationDefnType = {
          _required: false
        };
        cur[segment] = layer;
        cur = layer;
      }
    }
    reportMissing(newDefn);
  }

  return wDefn;
}

export function formHasPath(path: Array<string|number>, definition: Record<string,any>, ignoreFirst = 'data'): boolean {
  const items = path[0] === ignoreFirst
    ? path.slice(1)
    : path;
  return !!getValidationDefnByPath(items, definition);
}

export function getDocumentFieldChain(path: Array<string|number>, definition: DocumentFieldType) {
  const labels: Array<string> = [];
  let wDefn: DocumentFieldType | undefined = definition;
  for (const segment of path) {
    if (typeof segment === 'number' || segment.startsWith('[')) {
      wDefn = wDefn?._children;
      if (wDefn?._label) {
        labels.push(wDefn._label);
      }
      if (typeof segment === 'number') {
        labels.push((segment + 1).toString());
      }
    } else {
      wDefn = wDefn
        ? (wDefn as {[x: string]: DocumentFieldType})[segment]
        : undefined;
      if (wDefn?._label) {
        labels.push(wDefn._label);
      }
    }
  }

  switch (wDefn?._type) {
    case 'string':
    case 'number':
    case 'boolean':
    case 'Map':
      // only return something if the final item in the chain is labeled
      // the assumption being that we wouldn't want to put labels on 'hidden' fields
      return wDefn?._label
        ? labels
        : [];
    case 'Array':
    default:
      return [];
  }
}

/**
 *
 * Because validation trees treat lists like objects, we need to be able to reference it by an index.
 * To do so, we need to get the actual node at that location and read its ID, so that we can
 * reference the correct validation leaf. As to why we map list values to their ID keys, that's so
 * the validation reference doesn't change when we add and remove items. At the moment, I've set it
 * to recalculate the entire tree when elements are removed, due to not actually being able to see
 * the contents of removed elements to get the ID. This could potentially be calculated by exclusion
 * (find the missing ID) but this was quicker and I haven't seen performance issues from it.
 *
 * @param path
 * @param validationTree
 * @param absoluteNodeTree If not provided, IDs must be used for indexing, not array position
 * @returns
 */
export function getValidationValue(path: string[], validationTree: ValidationNode, absoluteNodeTree?: MaterialisedPropertyData) {
  let wDefn = validationTree;
  let wNode = absoluteNodeTree || {};
  for (const segment of path) {
    if (segment.startsWith('[')) {

      const parsed = segment.slice(1, segment.length-1);
      const numOrUUID = isUUID(parsed) ? parsed : (typeof parseInt(parsed) === 'number' && !isNaN(parseInt(parsed)) ? parseInt(parsed) : 0);
      if (typeof numOrUUID === 'number') {
        wNode = wNode?.[numOrUUID];
        const id = wNode?.id;
        if (id) {
          wDefn = wDefn?.[`[${id}]`];
        } else {
          return undefined;
        }
      } else {
        wDefn = wDefn?.[segment];
        wNode = wNode && find(wNode, node=>node.id === numOrUUID);
      }
    } else {
      wDefn = wDefn?.[segment];
      wNode = wNode?.[segment];
    }
  }
  return wDefn;
}

export function getNearestFieldMap(path: string, dataTreeTop: Record<string, any>) {
  const pathBits = path.split('.');
  while (pathBits.length > 0) {
    const searchPath = pathBits.join('.');
    const pathData = getValueByPath(searchPath, dataTreeTop);
    if (!Array.isArray(pathData) && typeof pathData === 'object') {
      return searchPath;
    }
    pathBits.splice(pathBits.length-1,1);
  }
  return '';
}
// Function overloading. Informing consumers that the type you put in is the type you get out
export function generateParentPath(pathParam: PathSegments, levelsUp?: number): PathSegments;
export function generateParentPath(pathParam: PathString, levelsUp?: number): PathString;
export function generateParentPath(pathParam: PathType, levelsUp = 1): PathType {
  const pathSegs = normalisePathToStrArray(pathParam);
  const sliced =  pathSegs.slice(0,pathSegs.length-levelsUp);
  return Array.isArray(pathParam) ? sliced : sliced.join('.');
}

export function generateParentPathWithProperty(pathParam: PathSegments): { parentPath: PathSegments, property: PathString };
export function generateParentPathWithProperty(pathParam: PathString): { parentPath: PathString, property: PathString };
export function generateParentPathWithProperty(pathParam: PathType): { parentPath: PathType, property: PathString } {
  const pathSegs = normalisePathToStrArray(pathParam);
  const parentPath =  pathSegs.slice(0, pathSegs.length-1);
  const property = pathSegs.at(-1) || '';

  return Array.isArray(pathParam)
    ? { parentPath, property }
    : { parentPath: parentPath.join('.'), property };
}

// The trick here is that a present change will have a truthy value, which just so happens to also
// be a map that we can traverse down.
export type PathHierarchy = {
  [pathComponent: string]: PathHierarchy
};
export function hierarchyMap(paths: string[][]): PathHierarchy {
  const result: PathHierarchy = {};
  for (const pathspec of paths) {
    let workingPath = result;
    for (const pathseg of pathspec) {
      if (!workingPath[pathseg] || typeof workingPath[pathseg] !== 'object') {
        workingPath[pathseg] = {};
      }
      workingPath = workingPath[pathseg];
    }
  }
  return result;
}

export function getHierarchyNode(path: PathSegments, changeTree: PathHierarchy | null | undefined) {
  let workingTree = changeTree;
  for (const pathSegI in path) {
    const pathSeg = path[pathSegI];
    if (pathSeg === '[*]') {

      for (const subtreeIndex in workingTree) {
        if (!subtreeIndex.startsWith('[')) continue;
        const subtree = workingTree?.[subtreeIndex];
        const remainingPath = path.slice((parseInt2(pathSegI)||0)+1);
        const maybeNode = getHierarchyNode(remainingPath, subtree);
        if (maybeNode) {
          return workingTree;
        }
      }
    }
    workingTree = workingTree?.[pathSeg];
  }
  return workingTree;
}

export function isPathInHierarchy(path: PathType, changeTree: PathHierarchy | null | undefined) {
  const normPath = normalisePathToStrArray(path);
  if (!changeTree) {
    return false;
  }
  return !!getHierarchyNode(normPath, changeTree);
}

export function uuidOrIndex(segment: string | undefined) {
  const retInvalid = {
    valid: false
  };
  if (!(typeof segment === 'string' && segment.startsWith('['))) {
    return retInvalid;
  }
  const idSection = segment.slice(0,segment.length-1).slice(1);
  if (isUUID(idSection)) {
    return {
      valid: true,
      uuid: idSection
    };
  }
  if (!isNaN(parseInt(idSection))) {
    return {
      valid: true,
      index: parseInt(idSection)
    };
  }
  return retInvalid;
}
