import { PartyType, SigningAuthorityType } from '@property-folders/contract';
import { formatAct } from '../pdfgen/formatters/clauses';
import { LegalJurisdiction } from '../pdfgen/constants';

/* Configuration Notes
positionContact1 & 2 determine if there are other contacts other than the vendor, and affect render.
If the vendor is the only contact, do not populate these fields
*/
export type FieldConfigObjType = {
  legalNameWizard?: string;
  legalName?: string;
  trustOrAppointedName?: string;
  person1Name?: string;
  person1NameMergedPdf?: string; // Special field for when we merge attorneys
  person2Name?: string;
  abnTitle?: string;
  email1?: string;
  phone1?: string;
  email2?: string;
  phone2?: string;
  streetAddr?: string;
  signingTemplate?: string;
  appointedAlwaysApplicable?: boolean;
  abnAlwaysApplicable?: boolean;
  registeredOnTitleDefault?: boolean;
  abnTitleDetection?: string; // format string for replacing with ABN or ACN
  positionContact1?: string;
  positionContact2?: string;
  positionContact1Display?: string;
  positionContact2Display?: string
  addressLinePos?: 'end' | 'underName' // for these position selectors, the default should be set to the first item set
};

type PartyFieldConfigType = {
  [partyType in PartyType]: {
    [signingAuth in SigningAuthorityType]?: FieldConfigObjType
  }
};

export const vendorWorksFieldPlaceholder = 'Vendor’s Works';

const corpTemplate: FieldConfigObjType =  {
  'legalName': 'Full legal name',
  'trustOrAppointedName': 'as trustee for and on behalf of',
  'abnTitle': 'ABN/ACN',
  'streetAddr': 'Postal address',
  'signingTemplate': `Executed by [fullLegalName] [axnType] [abn] pursuant to section 127(1) of the ${formatAct(LegalJurisdiction.Commonwealth, 'CorporationsAct2001')}`,
  'appointedAlwaysApplicable': false,
  'abnAlwaysApplicable': true,
  'registeredOnTitleDefault': false,
  abnTitleDetection: '${} of Corporation',
  addressLinePos: 'end'
};

const authRepTemplate: FieldConfigObjType = {
  ...corpTemplate,
  positionContact1: 'Authorised Representative for corporation',
  positionContact1Display: 'Authorised Representative',
  'person1Name': 'Authorised representative name',
  'email1': 'Email',
  'phone1': 'Mobile',
  'signingTemplate': `Executed by [fullLegalName] [axnType] [abn] pursuant to section 126 of the ${formatAct(LegalJurisdiction.Commonwealth, 'CorporationsAct2001')}`
};

const authRepTrusteeTemplate: FieldConfigObjType = {
  ...authRepTemplate,
  'legalName': 'Full legal name',
  positionContact1: 'Authorised Representative for Trustee Company',
  'streetAddr': 'Postal address',
  'abnTitle': 'ABN',
  abnTitleDetection: '${} of Trustee Company',
  'appointedAlwaysApplicable': true
};

const directors2Template: FieldConfigObjType = {
  ...corpTemplate,
  positionContact1: 'Director 1',
  positionContact1Display: 'Director',
  positionContact2: 'Director 2',
  positionContact2Display: 'Director',
  'person1Name': 'Director 1 name',
  'person2Name': 'Director 2 name',
  'email1': 'Director 1 email',
  'phone1': 'Director 1 mobile',
  'email2': 'Director 2 email',
  'phone2': 'Director 2 mobile'
};

const directors2TrusteeTemplate: FieldConfigObjType = {
  ...directors2Template,
  'legalName': 'Full legal name',
  'streetAddr': 'Postal address',
  'abnTitle': 'ABN',
  abnTitleDetection: '${} of Trustee Company',
  'appointedAlwaysApplicable': true
};

const directorSecretaryTemplate: FieldConfigObjType = {
  ...corpTemplate,
  positionContact1: 'Director',
  positionContact1Display: 'Director',
  positionContact2: 'Secretary',
  positionContact2Display: 'Secretary',
  'person1Name': 'Director name',
  'person2Name': 'Secretary name',
  'email1': 'Director email',
  'phone1': 'Director mobile',
  'email2': 'Secretary email',
  'phone2': 'Secretary mobile'
};
const directorSecretaryTrusteeTemplate: FieldConfigObjType = {
  ...directorSecretaryTemplate,
  'legalName': 'Full legal name',
  'streetAddr': 'Postal address',
  'abnTitle': 'ABN',
  abnTitleDetection: '${} of Trustee Company',
  'appointedAlwaysApplicable': true
};

const soleDirectorTemplate: FieldConfigObjType = {
  ...corpTemplate,
  positionContact1: 'Director',
  positionContact1Display: 'Sole Director/Secretary',
  'person1Name': 'Sole Director/Secretary name',
  'email1': 'Email',
  'phone1': 'Mobile'
};
const soleDirectorTrusteeTemplate: FieldConfigObjType = {
  ...soleDirectorTemplate,
  'legalName': 'Full legal name',
  'streetAddr': 'Postal address',
  'abnTitle': 'ABN',
  abnTitleDetection: '${} of Trustee Company',
  'appointedAlwaysApplicable': true
};

const corpAttorneyTemplate: FieldConfigObjType = {
  ...corpTemplate,
  positionContact1: 'Attorney',
  'person1Name': 'Attorney name',
  'email1': 'Email',
  'phone1': 'Mobile',
  'signingTemplate': `Executed by [fullLegalName] [axnType] [abn] pursuant to section 126 of the ${formatAct(LegalJurisdiction.Commonwealth, 'CorporationsAct2001')}`
};
const corpAttorneyTrusteeTemplate: FieldConfigObjType = {
  ...corpAttorneyTemplate,
  'legalName': 'Full legal name',
  'streetAddr': 'Postal address',
  'abnTitle': 'ABN',
  abnTitleDetection: '${} of Trustee Company',
  'appointedAlwaysApplicable': true
};

export const jointPartyConfig = {
  [PartyType.AdministratorJoint]: {
    appointedAlwaysApplicable: true,
    'trustOrAppointedName': 'as Administrators for the Estate of',
    streetAddr: 'Administrators\'s postal address',
    legalName: 'Legal Names of Administrators',
    abnTitle: 'ABN'
  },
  [PartyType.ExecutorJoint]: {
    appointedAlwaysApplicable: true,
    'trustOrAppointedName': 'as Executors for the Estate of',
    streetAddr: 'Executor\'s postal address',
    legalName: 'Legal Names of Executors',
    abnTitle: 'ABN'
  },
  [PartyType.MortgageeJoint]: {
    appointedAlwaysApplicable: false,
    'trustOrAppointedName': 'as trustee for and on behalf of',
    streetAddr: 'Mortgagee\'s postal address',
    legalName: 'Legal Names of Mortgagees',
    abnTitle: 'ABN'
  }
};

export const partyFieldConfig: PartyFieldConfigType = {
  [PartyType.Individual]: {
    [SigningAuthorityType.self]: {
      'trustOrAppointedName': 'as trustee for and on behalf of',
      'legalName': 'Full legal name',
      'email1': 'Email',
      'phone1': 'Mobile',
      'streetAddr': 'Postal address',
      'appointedAlwaysApplicable': false,
      'abnAlwaysApplicable': false,
      'registeredOnTitleDefault': true,
      'abnTitle': 'ABN',
      'signingTemplate': '[fullLegalName]'
    },
    [SigningAuthorityType.attorney]: {
      'legalNameWizard': 'Full legal name of Donor',
      'legalName': 'Full legal name',
      'trustOrAppointedName': 'as trustee for and on behalf of',
      'person1Name': 'Attorney name',
      'email1': 'Email',
      'phone1': 'Mobile',
      'streetAddr': 'Postal address',
      'appointedAlwaysApplicable': false,
      'abnAlwaysApplicable': false,
      'registeredOnTitleDefault': true,
      positionContact1: 'Attorney',
      'abnTitle': 'ABN',
      'signingTemplate': 'Signed by [personName1] as Attorney for [fullLegalName]'
    },
    [SigningAuthorityType.guardian]: {
      'legalNameWizard': 'Full legal name of Protected Person',
      'legalName': 'Full legal name',
      'trustOrAppointedName': 'as trustee for and on behalf of',
      'person1Name': 'Guardian name',
      'email1': 'Email',
      'phone1': 'Mobile',
      'streetAddr': 'Postal address',
      'appointedAlwaysApplicable': false,
      'abnAlwaysApplicable': false,
      'registeredOnTitleDefault': true,
      positionContact1: 'Guardian',
      'abnTitle': 'ABN',
      'signingTemplate': 'Signed by [personName1] as Guardian of [fullLegalName]'
    },
    [SigningAuthorityType.attorneyJoint]: {
      'legalNameWizard': 'Full legal name of Donor',
      'legalName': 'Full legal name',
      'trustOrAppointedName': 'as trustee for and on behalf of',
      'person1Name': 'Attorney name',
      'person1NameMergedPdf': 'Attorney\'s names',
      'email1': 'Email',
      'phone1': 'Mobile',
      'streetAddr': 'Postal address',
      'appointedAlwaysApplicable': false,
      'abnAlwaysApplicable': false,
      'registeredOnTitleDefault': true,
      positionContact1: 'Attorney',
      'abnTitle': 'ABN',
      'signingTemplate': 'Signed by [personName1] as Attorney for [fullLegalName]'
    },
    [SigningAuthorityType.guardianJoint]: {
      'legalNameWizard': 'Full legal name of Protected Person',
      'legalName': 'Full legal name',
      'trustOrAppointedName': 'as trustee for and on behalf of',
      'person1Name': 'Guardian name',
      'person1NameMergedPdf': 'Guardian\'s names',
      'email1': 'Email',
      'phone1': 'Mobile',
      'streetAddr': 'Postal address',
      'appointedAlwaysApplicable': false,
      'abnAlwaysApplicable': false,
      'registeredOnTitleDefault': true,
      positionContact1: 'Guardian',
      'abnTitle': 'ABN',
      'signingTemplate': 'Signed by [personName1] as Guardian of [fullLegalName]'
    }
  },
  [PartyType.Corporation]: {
    [SigningAuthorityType.authRep]: { ...authRepTemplate, registeredOnTitleDefault: true },
    [SigningAuthorityType.directors2]: { ...directors2Template, registeredOnTitleDefault: true },
    [SigningAuthorityType.directorSecretary]: { ...directorSecretaryTemplate, registeredOnTitleDefault: true },
    [SigningAuthorityType.sole]: { ...soleDirectorTemplate, registeredOnTitleDefault: true },
    [SigningAuthorityType.attorney]: { ...corpAttorneyTemplate, registeredOnTitleDefault: true },
    [SigningAuthorityType.attorneyJoint]: {
      ...corpAttorneyTemplate,
      'person1Name': 'Attorney name',
      'person1NameMergedPdf': 'Attorney\'s names',
      registeredOnTitleDefault: true
    }
  },
  [PartyType.ExecutorNatural]: {
    [SigningAuthorityType.self]: {
      'legalNameWizard': 'Full legal name of Executor',
      'legalName': 'Full legal name',
      'trustOrAppointedName': 'as Executor for the Estate of',
      'email1': 'Email',
      'phone1': 'Mobile',
      'streetAddr': 'Postal address',
      'appointedAlwaysApplicable': true,
      'abnAlwaysApplicable': true,
      'registeredOnTitleDefault': false,
      'abnTitle': 'ABN (if applicable)',
      'signingTemplate': 'Signed by [fullLegalName] as Executor for the Estate of [onBehalfOf]'
    },
    [SigningAuthorityType.attorney]: {
      'legalNameWizard': 'Full legal name of Executor',
      'legalName': 'Full legal name',
      'trustOrAppointedName': 'as Executor for the Estate of',
      'person1Name': 'Attorney name',
      'email1': 'Email',
      'phone1': 'Mobile',
      'streetAddr': 'Postal address',
      'appointedAlwaysApplicable': true,
      'abnAlwaysApplicable': false,
      'registeredOnTitleDefault': false,
      positionContact1: 'Executor\'s Attorney',
      'abnTitle': 'ABN',
      'signingTemplate': 'Signed by [personName1] as Attorney for [fullLegalName] in their capacity as Executor for the Estate of [onBehalfOf]'
    },
    [SigningAuthorityType.attorneyJoint]: {
      'legalNameWizard': 'Full legal name of Executor',
      'legalName': 'Full legal name',
      'trustOrAppointedName': 'as Executor for the Estate of',
      'person1Name': 'Attorney name',
      'person1NameMergedPdf': 'Attorney\'s names',
      'email1': 'Email',
      'phone1': 'Mobile',
      'streetAddr': 'Postal address',
      'appointedAlwaysApplicable': true,
      'abnAlwaysApplicable': false,
      'registeredOnTitleDefault': false,
      positionContact1: 'Executor\'s Attorney',
      'abnTitle': 'ABN',
      'signingTemplate': 'Signed by [personName1] as Attorney for [fullLegalName] in their capacity as Executor for the Estate of [onBehalfOf]'
    }
  },
  [PartyType.ExecutorCompany]: {
    [SigningAuthorityType.authRep]: { ...authRepTrusteeTemplate, 'trustOrAppointedName': 'as Executor for the Estate of', 'legalNameWizard': 'Full legal name of Executor' },
    [SigningAuthorityType.directors2]: { ...directors2TrusteeTemplate, 'trustOrAppointedName': 'as Executor for the Estate of', 'legalNameWizard': 'Full legal name of Executor' },
    [SigningAuthorityType.directorSecretary]: { ...directorSecretaryTrusteeTemplate, 'trustOrAppointedName': 'as Executor for the Estate of', 'legalNameWizard': 'Full legal name of Executor' },
    [SigningAuthorityType.sole]: { ...soleDirectorTrusteeTemplate, 'trustOrAppointedName': 'as Executor for the Estate of', 'legalNameWizard': 'Full legal name of Executor' },
    [SigningAuthorityType.attorney]: { ...corpAttorneyTrusteeTemplate, 'trustOrAppointedName': 'as Executor for the Estate of', 'legalNameWizard': 'Full legal name of Executor' },
    [SigningAuthorityType.attorneyJoint]: {
      ...corpAttorneyTrusteeTemplate,
      'person1Name': 'Attorney name',
      'person1NameMergedPdf': 'Attorney\'s names',
      'trustOrAppointedName': 'as Executor for the Estate of',
      'legalNameWizard': 'Full legal name of Executor'
    }
  },
  [PartyType.AdministratorNatural]: {
    [SigningAuthorityType.self]: {
      'legalNameWizard': 'Full legal name of Administrator',
      'legalName': 'Full legal name',
      'trustOrAppointedName': 'as Administrator for the Estate of',
      'email1': 'Email',
      'phone1': 'Mobile',
      'streetAddr': 'Postal address',
      'appointedAlwaysApplicable': true,
      'abnAlwaysApplicable': false,
      'registeredOnTitleDefault': false,
      'abnTitle': 'ABN',
      'signingTemplate': 'Signed by [fullLegalName] as Administrator for the Estate of [onBehalfOf]'
    },
    [SigningAuthorityType.attorney]: {
      'legalNameWizard': 'Full legal name of Administrator',
      'legalName': 'Full legal name',
      'trustOrAppointedName': 'as Administrator for the Estate of',
      'person1Name': 'Attorney name',
      'email1': 'Email',
      'phone1': 'Mobile',
      'streetAddr': 'Postal address',
      'appointedAlwaysApplicable': true,
      'abnAlwaysApplicable': false,
      'registeredOnTitleDefault': false,
      positionContact1: 'Administrator\'s Attorney',
      'abnTitle': 'ABN',
      'signingTemplate': 'Signed by [personName1] as Attorney for [fullLegalName] in their capacity as Administrator for the Estate of [onBehalfOf]'
    },
    [SigningAuthorityType.attorneyJoint]: {
      'legalNameWizard': 'Full legal name of Administrator',
      'legalName': 'Full legal name',
      'trustOrAppointedName': 'as Administrator for the Estate of',
      'person1Name': 'Attorney name',
      'person1NameMergedPdf': 'Attorney\'s names',
      'email1': 'Email',
      'phone1': 'Mobile',
      'streetAddr': 'Postal address',
      'appointedAlwaysApplicable': true,
      'abnAlwaysApplicable': false,
      'registeredOnTitleDefault': false,
      positionContact1: 'Administrator\'s Attorney',
      'abnTitle': 'ABN',
      'signingTemplate': 'Signed by [personName1] as Attorney for [fullLegalName] in their capacity as Administrator for the Estate of [onBehalfOf]'
    }
  },
  [PartyType.AdministratorCompany]: {
    [SigningAuthorityType.authRep]: { ...authRepTrusteeTemplate, 'trustOrAppointedName': 'as Administrator for the Estate of', 'legalNameWizard': 'Full legal name of Administrator' },
    [SigningAuthorityType.directors2]: { ...directors2TrusteeTemplate, 'trustOrAppointedName': 'as Administrator for the Estate of', 'legalNameWizard': 'Full legal name of Administrator' },
    [SigningAuthorityType.directorSecretary]: { ...directorSecretaryTrusteeTemplate, 'trustOrAppointedName': 'as Administrator for the Estate of', 'legalNameWizard': 'Full legal name of Administrator' },
    [SigningAuthorityType.sole]: { ...soleDirectorTrusteeTemplate, 'trustOrAppointedName': 'as Administrator for the Estate of', 'legalNameWizard': 'Full legal name of Administrator' },
    [SigningAuthorityType.attorney]: { ...corpAttorneyTrusteeTemplate, 'trustOrAppointedName': 'as Administrator for the Estate of', 'legalNameWizard': 'Full legal name of Administrator' },
    [SigningAuthorityType.attorneyJoint]: {
      ...corpAttorneyTrusteeTemplate,
      'person1Name': 'Attorney name',
      'person1NameMergedPdf': 'Attorney\'s names',
      'trustOrAppointedName': 'as Administrator for the Estate of',
      'legalNameWizard': 'Full legal name of Administrator'
    }
  },
  [PartyType.MortgageeNatural]: {
    [SigningAuthorityType.self]: {
      'trustOrAppointedName': 'as trustee for and on behalf of',
      'legalName': 'Full legal name',
      'email1': 'Email',
      'phone1': 'Mobile',
      'streetAddr': 'Postal address',
      'appointedAlwaysApplicable': false,
      'abnAlwaysApplicable': false,
      'registeredOnTitleDefault': false,
      'abnTitle': 'ABN',
      'signingTemplate': '[fullLegalName]'
    },
    [SigningAuthorityType.attorney]: {
      'legalName': 'Full legal name',
      'trustOrAppointedName': 'as trustee for and on behalf of',
      'person1Name': 'Attorney name',
      'email1': 'Email',
      'phone1': 'Mobile',
      'streetAddr': 'Postal address',
      'appointedAlwaysApplicable': false,
      'abnAlwaysApplicable': false,
      'registeredOnTitleDefault': false,
      positionContact1: 'Attorney',
      'abnTitle': 'ABN',
      'signingTemplate': 'Signed by [personName1] as Attorney for [fullLegalName]'
    },
    [SigningAuthorityType.guardian]: {
      'legalName': 'Full legal name',
      'trustOrAppointedName': 'as trustee for and on behalf of',
      'person1Name': 'Guardian name',
      'email1': 'Email',
      'phone1': 'Mobile',
      'streetAddr': 'Postal address',
      'appointedAlwaysApplicable': false,
      'abnAlwaysApplicable': false,
      'registeredOnTitleDefault': false,
      positionContact1: 'Guardian',
      'abnTitle': 'ABN',
      'signingTemplate': 'Signed by [personName1] as Guardian of [fullLegalName]'
    },
    [SigningAuthorityType.attorneyJoint]: {
      'legalName': 'Full legal name',
      'trustOrAppointedName': 'as trustee for and on behalf of',
      'person1Name': 'Attorney name',
      'person1NameMergedPdf': 'Attorney\'s names',
      'email1': 'Email',
      'phone1': 'Mobile',
      'streetAddr': 'Postal address',
      'appointedAlwaysApplicable': false,
      'abnAlwaysApplicable': false,
      'registeredOnTitleDefault': false,
      positionContact1: 'Attorney',
      'abnTitle': 'ABN',
      'signingTemplate': 'Signed by [personName1] as Attorney for [fullLegalName]'
    },
    [SigningAuthorityType.guardianJoint]: {
      'legalName': 'Full legal name',
      'trustOrAppointedName': 'as trustee for and on behalf of',
      'person1Name': 'Guardian name',
      'person1NameMergedPdf': 'Guardians\'s names',
      'email1': 'Email',
      'phone1': 'Mobile',
      'streetAddr': 'Postal address',
      'appointedAlwaysApplicable': false,
      'abnAlwaysApplicable': false,
      'registeredOnTitleDefault': false,
      positionContact1: 'Guardian',
      'abnTitle': 'ABN',
      'signingTemplate': 'Signed by [personName1] as Guardian of [fullLegalName]'
    }
  },
  [PartyType.MortgageeCompany]: {
    [SigningAuthorityType.authRep]: { ...authRepTemplate, registeredOnTitleDefault: false },
    [SigningAuthorityType.directors2]: { ...directors2Template, registeredOnTitleDefault: false },
    [SigningAuthorityType.directorSecretary]: { ...directorSecretaryTemplate, registeredOnTitleDefault: false },
    [SigningAuthorityType.sole]: { ...soleDirectorTemplate, registeredOnTitleDefault: false },
    [SigningAuthorityType.attorney]: { ...corpAttorneyTemplate, registeredOnTitleDefault: false },
    [SigningAuthorityType.attorneyJoint]: {
      ...corpAttorneyTemplate,
      'person1Name': 'Attorney name',
      'person1NameMergedPdf': 'Attorney\'s names',
      registeredOnTitleDefault: false
    }
  }
};

export const requirePropertyDetailsMessage = 'Specify the street address or title reference to identify the property';
