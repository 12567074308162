import { ContractSpecialType } from '@property-folders/contract';
import { canonicalisers, longMonthFormatter, numberOrdinal } from '../../formatting';
import { Predicate } from '../../../predicate';
import {
  documentSubTitle,
  drawUnderlineBetter,
  fieldFocus,
  fieldFocusMap, generateCheckboxText, generateCheckboxTextColumns,
  insertIf,
  itemSection,
  itemSubsection,
  lineIfEmpty,
  spaceStackLinesSideEffect
} from '..';
import { formatBI } from '../formatBI';
import { SaleAddress, SaleTitle } from '@property-folders/contract';
import { postSymbolForceSpacer } from '../formatters/postSymbolForceSpacer';
import { FieldPlaceholderStyle } from '../standards';

interface ExtraAttributes {
  decoration?: string,
  decorationStyle?: string
}

const olLowerAlpha = {
  type: 'lower-alpha',
  separator: ['(', ')' + postSymbolForceSpacer]
};
const olLowerRoman = {
  type: 'lower-roman',
  separator: ['(', ')' + postSymbolForceSpacer]
};

function text (text: string) {
  return { text };
}

export function offerFinanceSubsection(
  contractSpecial: ContractSpecialType | undefined,
  strikeThrough?: boolean
) {
  if (contractSpecial?.hideSection || contractSpecial?.hideFinance) {
    return [];
  }
  const financeAmount = canonicalisers.aud(contractSpecial?.financeAmount || '');
  return itemSubsection({
    subsectionTitle: undefined,
    titleLineContent: undefined,
    subsectionContent: spaceStackLinesSideEffect([
      generateCheckboxTextColumns('Subject to finance', contractSpecial?.financeRequired),
      spaceStackLinesSideEffect([
        { ul: spaceStackLinesSideEffect([
          { text: [
            'Loan amount: ',
            contractSpecial?.financeRequired && financeAmount.valid
              ? financeAmount.display
              : drawUnderlineBetter(FieldPlaceholderStyle.Price)
          ] },
          { text: [
            'Latest date for approval: ',
            contractSpecial?.financeRequired && contractSpecial.financeDeadline
              ? contractSpecial.financeDeadline
              : drawUnderlineBetter(FieldPlaceholderStyle.Date)
          ] },
          { text: [
            'Maximum interest rate acceptable: ',
            contractSpecial?.financeRequired && contractSpecial.financeInterestRate
              ? contractSpecial.financeInterestRate
              : drawUnderlineBetter(FieldPlaceholderStyle.Percentage)
          ] }
        ]) }
      ], 3)
    ]),
    unbreakable:
    undefined,
    bookmark: ['bookmark_offerFinance'],
    otherStackAttributes: strikeThrough ? { decoration: 'lineThrough' } : undefined
  });
}

function buildSecuredPropertyOl(addresses: string[]) {
  return addresses.map((addr, index, arr) => {
    let buildString = addr;

    if (index >= arr.length-1) {
      buildString = buildString+',';
      return buildString;
    }
    if (index === arr.length-2) {
      buildString = buildString+'; and';
      return buildString;
    }
    return buildString+';';
  });
}

enum PropertyFinanceRenderMode  {
  Empty,
  PropertyOnly,
  AlternateOnly,
  PropertyAndAlternatve,
  MultiAlternate
}

export function financeSubsection(
  contractSpecial: ContractSpecialType | undefined,
  isOffer: boolean | undefined,
  property?: {addresses?: SaleAddress[], titles?: SaleTitle[]},
  opts?: {
    templateAuctionMode?: boolean
    templateContractMode?: boolean
  }
) {
  if (contractSpecial?.hideSection || contractSpecial?.hideFinance) {
    return [];
  }
  const { templateAuctionMode = false, templateContractMode = false } = opts??{};
  const bookmark = ['bookmark_contractSpecialFinance', 'subsection-purchaser-finance', ...fieldFocusMap({ contractSpecial: [
    'financeRequired',
    'financeDeadline',
    'financeDeadlineTime',
    'financeAmount',
    'financeInterestRate',
    'financeProperties'
  ] })];
  const noFinance = Predicate.boolFalse(contractSpecial?.financeRequired) || (templateContractMode && Predicate.boolFalse(contractSpecial?.financePermitted));
  const noValues = noFinance || templateContractMode;
  const subsectionItems = [];
  const extraAttrs: ExtraAttributes = {};

  let renderMode = PropertyFinanceRenderMode.Empty;

  let oneOneD = {
    stack: [
      'secured against:',
      {
        ...olLowerRoman,
        ol: [
          { text: 'the Property; and' },
          { text: [lineIfEmpty('', FieldPlaceholderStyle.Location), '(insert any other property to be used as security for the loan)'] }
        ]
      }
    ]
  };

  if (!noValues) {
    const nonEmptyAlternativeSecurities = contractSpecial?.financeProperties?.filter(p=>p.addrSingleLine) ?? [];
    const noAlternativeProperties = !nonEmptyAlternativeSecurities.length;
    const notUsingProperty = !noAlternativeProperties && contractSpecial?.propertyNotPartOfSecurity;
    const nonEmptyMode = (contractSpecial?.financeAmount || contractSpecial?.financeDeadline || contractSpecial?.financeInterestRate);
    if (nonEmptyMode && noAlternativeProperties) {renderMode = PropertyFinanceRenderMode.PropertyOnly;}
    else if (nonEmptyMode && notUsingProperty && nonEmptyAlternativeSecurities.length > 1) {renderMode = PropertyFinanceRenderMode.MultiAlternate;}
    else if (nonEmptyMode && notUsingProperty && nonEmptyAlternativeSecurities.length === 1) {renderMode = PropertyFinanceRenderMode.AlternateOnly;}
    else if (nonEmptyMode && !notUsingProperty && !noAlternativeProperties) {renderMode = PropertyFinanceRenderMode.PropertyAndAlternatve;}

    switch (renderMode) {
      case PropertyFinanceRenderMode.PropertyOnly: {
        oneOneD = { text: 'secured against the Property,' };
        break;
      }
      case PropertyFinanceRenderMode.AlternateOnly: {
        oneOneD = { text: `secured against ${nonEmptyAlternativeSecurities[0]?.addrSingleLine},` };
        break;
      }
      case PropertyFinanceRenderMode.PropertyAndAlternatve: {
        oneOneD = { stack: [
          'secured against:',
          {
            ...olLowerRoman,
            ol: buildSecuredPropertyOl(['the Property', ...nonEmptyAlternativeSecurities.map(p=>p.addrSingleLine) ])
          }
        ] };
        break;
      }
      case PropertyFinanceRenderMode.MultiAlternate: {
        oneOneD = { stack: [
          'secured against:',
          {
            ...olLowerRoman,
            ol: buildSecuredPropertyOl(nonEmptyAlternativeSecurities.map(p=>p.addrSingleLine))
          }
        ] };
        break;
      }
    }
  }

  if (noFinance && isOffer) {
    return itemSubsection({
      subsectionTitle: undefined,
      titleLineContent: undefined,
      subsectionContent: spaceStackLinesSideEffect([{
        separator: ['SC\u00A0', ''],
        start: 1,
        ol: spaceStackLinesSideEffect([
          { text: 'FINANCE - Not applicable', bold: true }
        ])
      }]),
      unbreakable: undefined,
      bookmark: bookmark
    });
  }

  if (noFinance) {
    extraAttrs.decoration = 'lineThrough';
    extraAttrs.decorationStyle = 'solid';
  }

  subsectionItems.push({
    separator: ['SC\u00A0', ''],
    start: 1,
    lineHeight: 1.2,
    ol: spaceStackLinesSideEffect([
      { text: 'FINANCE', bold: true },
      {
        separator: ['SC\u00A01.', ''],
        start: 1,
        ol: spaceStackLinesSideEffect([
          {
            stack: [
              'Settlement under this Contract is conditional on the Purchaser obtaining conditional or unconditional approval in writing for a loan:',
              {
                ...olLowerAlpha,
                ol: [
                  {
                    text: [
                      'on or before 5:00pm on ',
                      lineIfEmpty(noValues ? null : contractSpecial?.financeDeadline, FieldPlaceholderStyle.Date),
                      formatBI(' (‘*-*Latest Date for Approval*-*’);')
                    ]
                  },
                  {
                    text: [
                      'for ',
                      lineIfEmpty(noValues ? null : contractSpecial?.financeAmount, FieldPlaceholderStyle.Price),
                      ' or such lesser amount the Purchaser accepts;'
                    ]
                  },
                  {
                    text: [
                      'at a rate no greater than ',
                      lineIfEmpty(noValues ? null : contractSpecial?.financeInterestRate, FieldPlaceholderStyle.Percentage),
                      '; and'
                    ]
                  },
                  oneOneD
                ]
              },
              formatBI('from a lender and otherwise on such terms and conditions that are acceptable to the Purchaser to assist in purchasing the Property (‘*-*Approval*-*’).')
            ]
          },
          {
            stack: [
              'The Purchaser must use its best endeavours to satisfy SC 1.1, which will include:',
              {
                ...olLowerAlpha,
                ol: [
                  'promptly applying for the Approval from a lender, whether direct to the lender or via a loan broker; ',
                  'diligently pursuing the Approval and supplying all things reasonably required by the lender for the purpose of the Approval; and',
                  'informing the Vendor regarding the progress of the Approval when reasonably requested by the Vendor or the Vendor’s Representative.'
                ]
              }
            ]
          },
          text('The Purchaser must notify the Vendor promptly after the Approval has been obtained.'),
          text('Upon notification of the Approval to the Vendor, this Special Condition will be satisfied notwithstanding that the lender may subsequently withdraw the Approval.'),
          text('The Purchaser may waive this Special Condition by serving notice of its waiver on the Vendor.'),
          text('In the event that this Special Condition is not satisfied or waived by the Latest Date for Approval, either party, and in the case of the Purchaser only where it has complied with SC 1.2, can immediately terminate this Contract by serving notice on the other party.'),
          {
            stack: [
              'In the event of termination of this Contract:',
              {
                ...olLowerAlpha,
                ol: [
                  'by a party pursuant to SC 1.6, all monies paid by or on behalf of the Purchaser must be repaid to the Purchaser; or',
                  'by the Vendor pursuant to General Condition 17.2 as a result of the Purchaser failing to comply with SC 1.2, the Vendor is entitled to the Deposit without limitation to its other rights under this Contract.'
                ]
              }
            ]
          }
        ])
      }
    ]),
    ...extraAttrs
  });

  return itemSubsection({
    subsectionTitle: undefined,
    titleLineContent: undefined,
    subsectionContent: spaceStackLinesSideEffect(subsectionItems),
    unbreakable:
    undefined,
    bookmark: bookmark,
    ...(templateAuctionMode ? { otherStackAttributes: { decoration: 'lineThrough' } } : undefined)
  });
}

export function offerPurchaserSaleSubsection(
  contractSpecial: ContractSpecialType | undefined,
  strikeThrough: boolean
) {
  if (contractSpecial?.hideSection || contractSpecial?.hideSaleRequired) {
    return [];
  }
  const sDate = contractSpecial?.purchaserSaleRequired
  && contractSpecial?.saleSettleAlsoRequired
  && contractSpecial?.purchaserSaleSettlementDeadline
    ? new Date(contractSpecial?.purchaserSaleSettlementDeadline)
    : undefined;

  const listItems = [
    { text: [
      'Sale property address: ',
      lineIfEmpty(contractSpecial?.purchaserSaleRequired && contractSpecial?.salePropertySingleLine, FieldPlaceholderStyle.Location)
    ] },
    { columns: [
      { text: 'Have you already entered into a Contract to sell the property?' },
      { width: 100, margin: [0, -6, 0, 0], text: [
        ...generateCheckboxText('Yes', contractSpecial?.purchaserSaleRequired && contractSpecial?.saleSettleAlsoRequired),
        ' ',
        ...generateCheckboxText('No', contractSpecial?.purchaserSaleRequired && Predicate.boolFalse(contractSpecial?.saleSettleAlsoRequired))
      ] }
    ] }
  ];

  const showHasContractBeenEnteredInto = contractSpecial?.purchaserSaleRequired == null || contractSpecial?.saleSettleAlsoRequired === undefined || (contractSpecial?.purchaserSaleRequired && contractSpecial?.saleSettleAlsoRequired);
  const contractDate = contractSpecial?.purchaserSaleRequired && contractSpecial?.purchaserSaleContractDeadline ? new Date(contractSpecial.purchaserSaleContractDeadline) : undefined;
  listItems.push({ text: [
    contractSpecial?.saleSettleAlsoRequired ? 'Date of contract entered into: on ' : 'Deadline for settling on contract: ',
    dateFormatterPdf(contractDate)
  ] });
  if (showHasContractBeenEnteredInto) {
    listItems.push({ text: [
      'Date of settlement under your sale Contract: on ',
      dateFormatterPdf(sDate)
    ] });
  } else {
    const dsDate = contractSpecial?.purchaserSaleRequired && contractSpecial?.purchaserSaleSettlementDeadline ? new Date(contractSpecial?.purchaserSaleSettlementDeadline) : undefined;
    listItems.push({ text: [
      'Deadline for settling on contract: ',
      dateFormatterPdf(dsDate)
    ] });
  }

  // desire is to always include this unless they've explicitly selected Yes
  if (showHasContractBeenEnteredInto) {
    listItems.push({ columns: [
      { text: 'Is your sale Contract unconditional?' },
      { width: 100, margin: [0, -6, 0, 0], text: [
        ...generateCheckboxText('Yes', contractSpecial?.purchaserSaleRequired && contractSpecial?.saleSettleAlsoRequired && contractSpecial?.saleSettleUnconditional),
        ' ',
        ...generateCheckboxText('No', contractSpecial?.purchaserSaleRequired && contractSpecial?.saleSettleAlsoRequired && Predicate.boolFalse(contractSpecial?.saleSettleUnconditional))
      ] }
    ] });

  }

  // desire is to always include this unless they've explicitly selected No
  if (contractSpecial?.purchaserSaleRequired == null || contractSpecial?.saleSettleAlsoRequired === undefined || (contractSpecial?.purchaserSaleRequired && Predicate.boolFalse(contractSpecial?.saleSettleAlsoRequired))) {
    listItems.push({ columns: [
      { text: 'Is your sale property currently listed for sale?' },
      { width: 100, margin: [0, -6, 0, 0], text: [
        ...generateCheckboxText('Yes', contractSpecial?.purchaserSaleRequired && Predicate.boolFalse(contractSpecial?.saleSettleAlsoRequired) && contractSpecial.salePropertyListedForSale),
        ' ',
        ...generateCheckboxText('No', contractSpecial?.purchaserSaleRequired && Predicate.boolFalse(contractSpecial?.saleSettleAlsoRequired) && Predicate.boolFalse(contractSpecial.salePropertyListedForSale))
      ] }
    ] });
    listItems.push({ text: [
      'Minimum price you need to sell for, to be able to purchase this property: ',
      lineIfEmpty(contractSpecial?.purchaserSaleRequired && contractSpecial?.purchaserSaleMinimumPrice, FieldPlaceholderStyle.Price)
    ] });
  }

  return itemSubsection({
    subsectionTitle: undefined,
    titleLineContent: undefined,
    subsectionContent: spaceStackLinesSideEffect([
      generateCheckboxTextColumns('Subject to Sale and/or Settlement of my/our property', contractSpecial?.purchaserSaleRequired),
      spaceStackLinesSideEffect([
        { ul: spaceStackLinesSideEffect(listItems) }
      ], 3)
    ]),
    unbreakable:
    undefined,
    bookmark: ['bookmark_offerPurchaserSale'],
    otherStackAttributes: strikeThrough ? { decoration: 'lineThrough' } : undefined
  });
}

export function dateFormatterPdf(date?: Date) {
  return lineIfEmpty(date ? `${numberOrdinal(date.getDate()) ?? ''} ${longMonthFormatter(date)} ${date.getFullYear()}` : undefined, FieldPlaceholderStyle.Date);
}

export function purchaserSaleSubsection(
  contractSpecial: ContractSpecialType | undefined,
  opts?: {
    isOffer?: boolean,
    templateAuctionMode?: boolean
    templateContractMode?: boolean
  }
) {
  if (contractSpecial?.hideSaleRequired || contractSpecial?.hideSection) {
    return [];
  }
  const { templateAuctionMode = false, templateContractMode = false, isOffer = false } = opts??{};
  const bookmark = ['bookmark_contractSpecialPurchaserSale', 'subsection-purchaser-sale', ...fieldFocusMap({ contractSpecial: [
    'purchaserSaleRequired',
    'saleSettleAlsoRequired',
    'salePropertySingleLine',
    'purchaserSaleContractDeadline',
    'purchaserSaleSettlementDeadline',
    'purchaserSaleMinimumPrice'
  ] })];

  const noPurchaserSale = Predicate.boolFalse(contractSpecial?.purchaserSaleRequired)  || (templateContractMode && Predicate.boolFalse(contractSpecial?.purchaserSalePermitted));
  const noValues = noPurchaserSale || templateContractMode;
  const extraAttrs: ExtraAttributes = {};
  const subsectionItems = [];
  const cDate = !noValues && contractSpecial?.purchaserSaleContractDeadline ? new Date(contractSpecial?.purchaserSaleContractDeadline) : undefined;
  const sDate = !noValues && contractSpecial?.purchaserSaleSettlementDeadline ? new Date(contractSpecial?.purchaserSaleSettlementDeadline) : undefined;
  const clauseIsDecided = !noValues && contractSpecial?.purchaserSaleRequired && !Predicate.isNullish(contractSpecial?.saleSettleAlsoRequired);

  if (noPurchaserSale && isOffer) {
    return itemSubsection({
      subsectionTitle: undefined,
      titleLineContent: undefined,
      subsectionContent: spaceStackLinesSideEffect([{
        separator: ['SC\u00A0', ''],
        start: 2,
        ol: spaceStackLinesSideEffect([
          { text: 'SALE OF THE PURCHASER’S PROPERTY - Not applicable', bold: true }
        ])
      }]),
      unbreakable: undefined,
      bookmark: bookmark });
  }

  if (noPurchaserSale) {
    extraAttrs.decoration = 'lineThrough';
    extraAttrs.decorationStyle = 'solid';
  }

  subsectionItems.push({
    separator: ['SC\u00A0', ''],
    start: 2,
    lineHeight: 1.2,
    ol: spaceStackLinesSideEffect([
      { text: [{ text: 'SALE OF THE PURCHASER’S PROPERTY', bold: true }] },
      {
        separator: ['SC\u00A02.', ''],
        start: 1,
        ol: spaceStackLinesSideEffect([
          {
            stack: [
              formatBI('(/-/* select the applicable option/-/)'),
              {
                stack: [
                  {
                    text: [
                      ...insertIf(!clauseIsDecided, { text: '☐', style: 'checkbox', font: 'DejaVu', fontSize: 13, baselineOffset: 1.5 }),
                      { text: '  Settlement under this Contract is conditional on the Purchaser:' }
                    ],
                    margin: [clauseIsDecided?0:-17,0,0,0]
                  },
                  {
                    ...olLowerAlpha,
                    ol: [
                      {
                        stack: [
                          {
                            text: [
                              'entering into a contract on or before ',
                              dateFormatterPdf(cDate),
                              ' for the sale of property at:'
                            ]
                          },
                          lineIfEmpty(noValues ? null : contractSpecial?.salePropertySingleLine, FieldPlaceholderStyle.Location)
                        ]
                      },
                      {
                        text: [
                          'for a price of not less than ',
                          lineIfEmpty(noValues ? null : contractSpecial?.purchaserSaleMinimumPrice, FieldPlaceholderStyle.Price),
                          ' or such lesser sum that the Purchaser accepts; and'
                        ]
                      },
                      {
                        text: [
                          'settlement of that contract occurring on or before ',
                          dateFormatterPdf(sDate),
                          ' .'
                        ]
                      }
                    ]
                  }
                ],
                ...(contractSpecial?.purchaserSaleRequired && contractSpecial?.saleSettleAlsoRequired && { decoration: 'lineThrough' })
              },
              {
                stack: [
                  {
                    text: [
                      {
                        text: [
                          ...insertIf(!clauseIsDecided, { text: '☐', style: 'checkbox', font: 'DejaVu', fontSize: 13, baselineOffset: 1.5 }),
                          '  Settlement under this Contract is conditional on the sale of the Purchaser’s property at: '
                        ]
                      }
                    ],
                    margin: [clauseIsDecided?0:-17,4,0,0]
                  },
                  {
                    text: [' ', lineIfEmpty(noValues ? null : contractSpecial?.salePropertySingleLine, FieldPlaceholderStyle.Location)]
                  },
                  {
                    text: [
                      text(' settling on or before '),
                      dateFormatterPdf(sDate),
                      text(' pursuant to a contract entered into by the Purchaser dated '),
                      dateFormatterPdf(cDate),
                      '.'
                    ]
                  }
                ],
                ...(contractSpecial?.purchaserSaleRequired && Predicate.boolFalse(contractSpecial?.saleSettleAlsoRequired) && { decoration: 'lineThrough' })
              }
            ]
          },
          {
            stack: [
              'The Purchaser must:',
              {
                ...olLowerAlpha,
                ol: [
                  'use its best endeavours to satisfy SC 2.1; and',
                  'at the Vendor’s reasonable request, promptly inform the Vendor on the status of satisfaction of SC 2.1.'
                ]
              }
            ]
          },
          text('The Purchaser may waive this Special Condition by serving notice of its waiver on the Vendor.'),
          text('In the event that SC 2.1 is not satisfied or waived within the time agreed in SC 2.1, either party, and in the case of the Purchaser only where it has complied with SC 2.2, can immediately terminate this Contract by serving notice to the other party.'),
          {
            stack: [
              'In the event of termination of this Contract:',
              {
                ...olLowerAlpha,
                ol: [
                  'by a party pursuant to SC 2.4, all monies paid by or on behalf of the Purchaser must be repaid to the Purchaser; or ',
                  'by the Vendor pursuant to General Condition 17.2 as a result of the Purchaser failing to comply with SC 2.2, the Vendor is entitled to the Deposit without limitation to its other rights under this Contract.'
                ]
              }
            ]
          }
        ])
      }
    ]),
    ...extraAttrs
  });

  return itemSubsection({
    subsectionTitle: undefined,
    titleLineContent: undefined,
    subsectionContent: spaceStackLinesSideEffect(subsectionItems),
    unbreakable: undefined,
    bookmark: bookmark,
    ...(templateAuctionMode ? { otherStackAttributes: { decoration: 'lineThrough' } } : undefined)
  });
}

export function contractSpecialConditionsSection(
  contractSpecial: ContractSpecialType | undefined,
  isOffer?: boolean,
  property?: {addresses?: SaleAddress[], titles?: SaleTitle[]},
  opts?: {
    templateAuctionMode: boolean
    templateContractMode?: boolean
  }

) {

  const { templateAuctionMode = false, templateContractMode } = opts??{};
  if (contractSpecial?.hideSection || (contractSpecial?.hideFinance && contractSpecial.hideSaleRequired) || templateAuctionMode) { // The default in the template contract in auction mode is now to not show these at all
    return [];
  }

  const sectionItems = [];
  sectionItems.push(financeSubsection(contractSpecial, isOffer, property??{}, { templateAuctionMode, templateContractMode }));
  sectionItems.push(purchaserSaleSubsection(contractSpecial, { isOffer, templateAuctionMode, templateContractMode }));
  return itemSection({
    itemNo: undefined,
    itemTitleParam: '',
    bookmark: 'bookmark_special',
    stackContent: [
      documentSubTitle('Special Conditions'.toUpperCase()),
      ...sectionItems
    ],
    superTitle: true,
    pageBreakBefore: true
  });
}

export function offerSpecialConditionsSection(
  itemNo: number,
  contractSpecial: ContractSpecialType | undefined,
  hasAdditionalConditions: boolean
) {
  if (contractSpecial?.hideSection || (contractSpecial?.hideFinance && contractSpecial.hideSaleRequired)) {
    return [];
  }
  const skipSection = contractSpecial?.financePermitted === false && contractSpecial?.purchaserSalePermitted === false;
  const showNothing = skipSection && !hasAdditionalConditions;
  if (showNothing) return [];

  if (skipSection) return itemSection({
    itemNo,
    itemTitleParam: 'Special Conditions'.toUpperCase(),
    bookmark: 'bookmark_special',
    stackContent: spaceStackLinesSideEffect([{ text: 'See Additional Conditions below that may apply.' }])
    // superTitle: true
  });

  const sectionItems = [];
  const doStrikeThrough = contractSpecial?.financeRequired === false && contractSpecial?.purchaserSaleRequired === false;
  sectionItems.push({ text: 'If selected, complete the relevant fields', decoration: doStrikeThrough ? 'lineThrough' : undefined });

  if (contractSpecial?.financePermitted !== false) sectionItems.push(offerFinanceSubsection(contractSpecial, contractSpecial?.financeRequired === false));
  if (contractSpecial?.purchaserSalePermitted !== false) sectionItems.push(offerPurchaserSaleSubsection(contractSpecial, contractSpecial?.purchaserSaleRequired === false));

  return itemSection({
    itemNo,
    itemTitleParam: 'Special Conditions'.toUpperCase(),
    bookmark: 'bookmark_special',
    stackContent: spaceStackLinesSideEffect(sectionItems)
    // superTitle: true
  });
}

export function offerAdditionalConditionsSection(
  itemNo: number,
  offerAdditionalConditions: string | undefined
) {
  if (!offerAdditionalConditions) return [];

  return itemSection({
    itemNo,
    itemTitleParam: 'Additional Conditions'.toUpperCase(),
    bookmark: ['bookmark_offerAdditionalConditions', 'subsection-offer-additional-conditions', ...fieldFocus('offerAdditionalConditions')],
    stackContent: spaceStackLinesSideEffect([
      { text: 'If you require any additional conditions be included in your offer, please set those out here:' },
      { text: offerAdditionalConditions }
    ])
  });
}
