import { MaterialisedPropertyData, TransactionMetaData } from '@property-folders/contract';

/**
 * Subscription form codes.
 * The value matches the FormFileName legacy column
 */
export enum SubscriptionFormCode {
  SAF001V2_Form1 = 'SAF001V2',
  SACS015_LicenceToOccupy = 'SACS015',
  SAR014_NoticeOfOfferToPurchaseResidentialLand = 'SAR014',
  SAR008_VendorQuestionnaire = 'SAR008'
}

/**
 * The type of editor to reveal in a modal
 */
export type EditorType =
  | 'vendor'
  | 'purchaser'
  | 'agent'
  // authorised representative if in 3rd party filling mode
  // else, agent
  | 'agent_or_representative'
  | 'property'
  | 'contract_settlement'
  | 'none';

export type StringifyFn = (data: MaterialisedPropertyData, meta: TransactionMetaData) => string;
export interface ChangeSetItem { targetName: string, value: string }
/**
 * Function which takes in property data and returns a list of changes to apply to elements targeted by their name.
 */
export type BuildChangeSetFn = (data: MaterialisedPropertyData, meta: TransactionMetaData) => ChangeSetItem[];

/**
 * This is an internal definition type
 */
export interface EditFieldInfo {
  editor: EditorType,
  editorFiller?: EditorType,
  stringify: StringifyFn,
  disabled?: boolean
}

export interface FormFields {
  fields: Record<string, EditFieldInfo | undefined>;
}

/**
 * This essentially wraps the information provided by FormFields / EditFieldInfo
 * into a payload more usable by frontend dragged-components
 */
export interface EditorInfo {
  editor: EditorType,
  getChanges: BuildChangeSetFn
}

type EditFunction = (document: Document, debug?: boolean) => void;
export interface EditHtmlDefinition {
  editFns: EditFunction[]
}
