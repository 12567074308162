import { PDFDocument } from 'pdf-lib';

export const stitch = async (pdfs: (Uint8Array | ArrayBuffer)[], idxBase = 0): Promise<Uint8Array | ArrayBuffer> => {
  if (pdfs?.length < 2) return pdfs?.[0];
  if (idxBase >= pdfs.length) {
    idxBase = pdfs.length - 1;
  }

  const mergedPdf = await PDFDocument.load(pdfs[idxBase]);
  let idxPrepend = 0;
  for (let i = 0; i < pdfs.length; ++i) {
    if (i === idxBase) continue;
    const toMerge = await PDFDocument.load(pdfs[i]);
    const copiedPages = await mergedPdf.copyPages(toMerge, toMerge.getPageIndices());
    if (i > idxBase) {
      copiedPages.forEach(page => {
        mergedPdf.addPage(page);
      });
    } else {
      copiedPages.forEach(page => {
        mergedPdf.insertPage(idxPrepend, page);
        idxPrepend++;
      });
    }
  }

  return await mergedPdf.save();
};
